<template>
  <div>
    <div
      v-if="isLoading"
      class="fixed inset-0 bg-white z-50 bg-opacity-75 transition-opacity"
    >
      <div class="h-full flex items-center justify-center">
        <brightbid-loader size="120">
          <p>Sending Invitation...</p>
        </brightbid-loader>
      </div>
    </div>

    <div class="text-center text-bb-text-default m-10">
      <h3 class="h3">Invite User</h3>
      <p>Invite users who will have access to the selected Organizations and their respective Sites.</p>
    </div>
    <div class="my-10 overflow-y-auto max-h-100 base-scrollbar">
      <form class="mx-10 mb-60">
        <div class="space-y-6">
          <text-input
            v-model="inviteUserForm.email"
            :disabled="isLoading"
            label="Email"
            input-name="email"
            input-id="email"
            input-type="email"
            placeholder="email@gmail.com"
            :error-message="emailErrorMessage"
            @focusout="$v.inviteUserForm.email.$touch"
          />

          <div>
            <p class="text-sm font-medium">Role</p>
            <div class="flex items-center gap-4 mt-3">
              <div
                v-for="option in roleOptions"
                :key="option.value"
                class="flex items-start gap-4 cursor-pointer p-4 w-full h-full rounded-md border transition duration-500 ease-in-out"
                :class="$v.inviteUserForm.role.$error ? 'border-bb-error' : 'border-bb-neutral-100'"
                @click="inviteUserForm.role = option.value"
              >
                <radio-button :checked="inviteUserForm.role === option.value" />
                <div class="text-bb-text-default">
                  <p class="text-normal font-medium">
                    {{ option.label }}
                  </p>
                  <p class="text-xs">
                    {{ option.description }}
                  </p>
                </div>
              </div>
            </div>
            <p
              v-if="$v.inviteUserForm.role.$error"
              class="text-xs text-bb-error mt-2"
            >
              A role is required to invite a user.
            </p>
          </div>
          <div class="space-y-6">
            <div>
              <h4 class="h4">Organizations</h4>
              <p class="text-sm text-bb-text-secondary">Choose one or more organizations to be managed by this user.</p>
            </div>
            <div class="grid grid-cols-2 items-center gap-6">
              <div
                v-for="(organization, index) in $v.inviteUserForm.organizations.$each.$iter"
                :key="`${organization.$model.value}-${index}`"
              >
                <div class="w-87 flex items-center gap-1">
                  <search-input
                    v-model="organization.$model"
                    v-scroll-to-me
                    :input-name="organization.$model.value"
                    :input-id="index"
                    :options="filteredOptions"
                    class="w-full"
                    placeholder="Select organization..."
                    :error-message="organization.$error ? 'Please select an organization' : null"
                    @blur="organization.$touch"
                    @select-item="updateOrganizationList"
                  />
                  <ic-cross
                    v-if="inviteUserForm.organizations.length > 1"
                    @close="removeOrganizationInput(index)"
                  />
                </div>
              </div>
              <div
                class="cursor-pointer"
                @click="addOrganizationInput"
              >
                <p
                  class="font-medium"
                  :class="hasAtLeastOneOrganization ? 'text-bb-brand-purple' : 'text-bb-disabled-gray'"
                >
                  + Add Organization
                </p>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <!--SAVE AND CANCEL BUTTONS-->
    <div class="flex justify-between items-center m-10">
      <merge-button-round
        :disabled="isLoading"
        button-type="secondary"
        class="w-40"
        @click="$emit('close')"
      >
        <p>Cancel</p>
      </merge-button-round>
      <merge-button-round
        :disabled="isLoading"
        button-type="primary"
        class="w-40"
        @click="save"
      >
        <p>Save</p>
      </merge-button-round>
    </div>
  </div>
</template>

<script>
import { required, email, helpers } from 'vuelidate/lib/validators'
import IcCross from '@/components/icon/ic-cross'
import TextInput from '@/components/input/brightbid/TextInput'
import RadioButton from '@/components/input/brightbid/RadioButton'
import SearchInput from '@/components/input/brightbid/SearchInput'
import BrightbidLoader from '@/components/loader/BrightbidLoader'
import MergeButtonRound from '@/components/btn/MergeButtonRound'

const defaultOrganization = { value: null, label: null }

export default {
  name: 'InviteUserModalForm',
  components: {
    TextInput,
    RadioButton,
    SearchInput,
    IcCross,
    BrightbidLoader,
    MergeButtonRound,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    defaultOrganization: {
      type: Object,
      default: () => defaultOrganization,
    },
    existingUser: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      inviteUserForm: {
        email: null,
        role: null,
        organizations: null,
      },
      roleOptions: [
        {
          label: 'Manager',
          value: 'manager',
          description: 'Can invite/remove team members and update team settings.',
        },
        {
          label: 'Standard',
          value: 'standard',
          description: 'A limited member of your team. They can only access informations.',
        },
      ],
    }
  },
  computed: {
    filteredOptions() {
      return this.options.filter(item => !this.inviteUserForm.organizations.some(org => org.value === item.value))
    },
    hasAtLeastOneOrganization() {
      const organizations = this.inviteUserForm.organizations
      // Check if organizations array exists, has at least one item, and the first organization has a defined value
      return (
        organizations &&
        organizations.length > 0 &&
        organizations[0].value !== undefined &&
        organizations[0].value !== null
      )
    },
    emailErrorMessage() {
      if (!this.$v.inviteUserForm.email.$dirty) return ''
      if (!this.$v.inviteUserForm.email.required || !this.$v.inviteUserForm.email.email)
        return this.$v.inviteUserForm.email.$params.email.errorMessage
      if (this.existingUser === this.inviteUserForm.email)
        return 'This email is already associated with an existing user'
      return ''
    },
  },
  mounted() {
    if (this.defaultOrganization !== defaultOrganization) {
      this.inviteUserForm.organizations = [
        { label: this.defaultOrganization.organization, value: this.defaultOrganization.id },
      ]
    } else {
      this.inviteUserForm.organizations = [this.defaultOrganization]
    }
  },
  validations() {
    return {
      inviteUserForm: {
        email: {
          required: helpers.withParams({ errorMessage: 'Please enter a valid email' }, required),
          email: helpers.withParams({ errorMessage: 'Please enter a valid email' }, email),
        },
        role: { required },
        organizations: {
          required,
          $each: { label: { required }, value: { required } },
        },
      },
    }
  },
  methods: {
    addOrganizationInput() {
      if (!this.hasAtLeastOneOrganization) return
      const emptySearchInput = { value: '', label: '' }
      this.inviteUserForm.organizations.push(emptySearchInput)
    },
    removeOrganizationInput(index) {
      if (this.inviteUserForm.organizations.length === 1) return
      this.inviteUserForm.organizations.splice(index, 1)
    },
    save() {
      this.$v.inviteUserForm.$touch()
      if (this.$v.inviteUserForm.$invalid) return
      this.$emit('save', this.inviteUserForm)
    },
    updateOrganizationList(data, index) {
      this.inviteUserForm.organizations.splice(index, 1, data)
    },
  },
}
</script>

<style scoped></style>
