<template>
  <div class="flex flex-col border-l">
    <div
      v-if="isLoading"
      class="fixed inset-0 bg-white z-50 bg-opacity-75 transition-opacity"
    >
      <div class="h-full flex items-center justify-center">
        <brightbid-loader size="120">
          <p>Updating organization name...</p>
        </brightbid-loader>
      </div>
    </div>
    <div class="px-6 border-b">
      <div class="flex justify-between items-center pt-2">
        <div @click="close">
          <ic-chevron
            direction="right"
            :size="16"
          />
        </div>

        <kebab
          :menu="menu"
          :item-data="{}"
          @select-action="openModal"
        />
      </div>

      <div class="py-10 flex flex-col items-center">
        <ic-avatar size="40" />
        <p class="h3">
          {{ selectedUser.name }}
        </p>
        <span class="p1 text-bb-text-secondary">{{ selectedUser.email }}</span>
        <span class="bg-neutral-0 py-1 px-3 rounded-full text-bb-text-secondary">{{ selectedUser.role.name }}</span>
        <span class="p1 text-bb-text-secondary py-4">
          Last Activity: {{ dateToString(new Date(selectedUser.last_login_at)) }}
        </span>
        <merge-button-round
          button-type="secondary"
          class="flex gap-x-2"
          @click="$emit('toggle-assign-user-org-modal')"
        >
          <div class="flex gap-x-2"><ic-plus /> Assign Organization</div>
        </merge-button-round>
      </div>
    </div>

    <div class="pl-6 pr-12 py-4 organizations-container base-scrollbar">
      <div
        class="h5 flex pb-2"
        @click="toggleShowOrganizations"
      >
        Organizations <span class="text-bb-text-secondary"> ({{ selectedUser.organizations.length }})</span>
        <ic-chevron
          :direction="chevronDirection"
          class="text-bb-text-secondary"
        />
      </div>

      <template v-if="showOrganizations">
        <div
          v-for="org in selectedUser.organizations"
          :key="org.id"
          class="flex justify-between py-4"
        >
          <div class="p1">
            {{ org.name }}
          </div>
          <div class="flex gap-x-2 items-center p2">
            <div
              class="h-3 w-3 rounded-full"
              :class="{ 'bg-success': org.active, 'bg-bb-error': !org.active }"
            />
            {{ org.active ? 'Active' : 'Inactive' }}
          </div>
        </div>
      </template>
    </div>

    <!--MODAL COMPONENTS-->
    <bb-base-modal
      v-if="showDeleteUserConfirmModal"
      width="420px"
      height="340px"
      :form-values="{}"
      @close="closeModal()"
    >
      <template #container="{}">
        <div class="flex flex-col items-center p-8">
          <h3 class="h3 pb-4">Delete User</h3>
          <p>Are you sure you want to delete this user?</p>
          <div
            class="p-4 rounded-lg border border-bb-warning flex items-center mt-4 bg-bb-warning-100 text-bb-warning-200"
          >
            <div class="pt-1">
              <icInfo
                :size="24"
                class="my-auto cursor-pointer"
              />
            </div>

            <div class="pl-4 text-sm">
              <span class="h5 mb-2">Warning!</span>
              <p class="p2">By deleting this account, the user won't be able to access the organizations.</p>
            </div>
          </div>
          <!--SAVE AND CANCEL BUTTONS-->
          <div class="flex justify-between items-center py-6 mt-2 space-x-8">
            <MergeButtonRound
              button-type="secondary"
              class="w-40"
              @click="closeModal"
            >
              <p>No, Cancel</p>
            </MergeButtonRound>
            <MergeButtonRound
              button-type="primary"
              class="w-40"
              @click="deleteUser"
            >
              <p>Yes, Delete</p>
            </MergeButtonRound>
          </div>
        </div>
      </template>
    </bb-base-modal>
    <bb-base-modal
      v-if="showEditRoleModal"
      width="420px"
      height="340px"
      :form-values="{}"
      @close="closeModal()"
    >
      <template #container="{}">
        <div class="flex flex-col items-center p-8">
          <h3 class="h3 pb-4">Edit user roles</h3>
          <form>
            <div>
              <p class="text-sm font-medium">Role</p>
              <div class="flex items-center gap-4 mt-3">
                <div
                  v-for="option in roleOptions"
                  :key="option.value"
                  class="flex items-start gap-4 cursor-pointer p-4 w-full h-full rounded-md border transition duration-500 ease-in-out"
                  :class="$v.editUserForm.role.$error ? 'border-bb-error' : 'border-bb-neutral-100'"
                  @click="editUserForm.role = option.value"
                >
                  <radio-button :checked="editUserForm.role === option.value" />
                  <div class="text-bb-text-default">
                    <p class="text-normal font-medium">
                      {{ option.label }}
                    </p>
                    <p class="text-xs">
                      {{ option.description }}
                    </p>
                  </div>
                </div>
              </div>
              <p
                v-if="$v.editUserForm.role.$error"
                class="text-xs text-bb-error mt-2"
              >
                A role is required to invite a user.
              </p>
            </div>
          </form>
          <!--SAVE AND CANCEL BUTTONS-->
          <div class="flex justify-between items-center py-6 mt-2 space-x-8">
            <MergeButtonRound
              button-type="secondary"
              class="w-40"
              @click="closeModal"
            >
              <p>Cancel</p>
            </MergeButtonRound>
            <MergeButtonRound
              button-type="primary"
              class="w-40"
              @click="editUserRole"
            >
              <p>Save</p>
            </MergeButtonRound>
          </div>
        </div>
      </template>
    </bb-base-modal>
  </div>
</template>

<script>
import IcChevron from '@/components/icon/ic-chevron.vue'
import IcAvatar from '@/components/icon/ic-avatar.vue'
import IcPlus from '@/components/icon/ic-plus'
import IcInfo from 'vue-material-design-icons/InformationOutline'
import MergeButtonRound from '@/components/btn/MergeButtonRound'
import Kebab from '@/components/icon/brightbid/kebab'
import BbBaseModal from '@/components/modals/brightbid/BbBaseModal'
import BrightbidLoader from '@/components/loader/BrightbidLoader'
import Toast from '@/components/shared/Toast'
import { dateToString } from '@/utils/date'
import { required } from 'vuelidate/lib/validators'
import RadioButton from '@/components/input/brightbid/RadioButton'

const DELETE_USER_ACTION = 'delete-user'
const EDIT_ROLE_ACTION = 'edit-role'

export default {
  name: 'UserDetail',
  components: {
    IcChevron,
    IcAvatar,
    IcInfo,
    MergeButtonRound,
    IcPlus,
    Kebab,
    BbBaseModal,
    BrightbidLoader,
    RadioButton,
  },
  props: {
    selectedUser: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showOrganizations: true,
      showDeleteUserConfirmModal: false,
      showEditRoleModal: false,
      isLoading: false,
      menu: [
        { label: 'Delete User', value: DELETE_USER_ACTION },
        { label: 'Edit Role', value: EDIT_ROLE_ACTION },
      ],
      editUserForm: {
        role: null,
      },
      roleOptions: [
        {
          label: 'Manager',
          value: 'manager',
          description: 'Can invite/remove team members and update team settings.',
        },
        {
          label: 'Standard',
          value: 'standard',
          description: 'A limited member of your team. They can only access informations.',
        },
      ],
    }
  },
  computed: {
    chevronDirection() {
      return this.showOrganizations ? 'down' : 'up'
    },
  },
  created() {
    this.editUserForm.role = this.selectedUser.role.name
  },
  validations() {
    return {
      editUserForm: {
        role: { required },
      },
    }
  },
  methods: {
    toggleShowOrganizations() {
      this.showOrganizations = !this.showOrganizations
    },
    close() {
      this.$emit('close')
    },
    openModal(modal) {
      if (modal.value === DELETE_USER_ACTION) this.showDeleteUserConfirmModal = true
      if (modal.value === EDIT_ROLE_ACTION) this.showEditRoleModal = true
    },
    closeModal() {
      this.showDeleteUserConfirmModal = false
      this.showEditRoleModal = false
    },
    async deleteUser() {
      try {
        this.isLoading = true
        await this.$http.delete(`/common/user/${this.selectedUser.id}`)
        this.showDeleteUserConfirmModal = false
        this.$toast.success({
          component: Toast,
          props: {
            title: 'Success',
            message: 'Successfully deleted a user.',
            type: 'success',
          },
        })
        this.$emit('refresh-users')
      } catch (e) {
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Something went wrong',
            message: 'The action did not proceed as expected. Please try again',
            type: 'error',
          },
        })
      } finally {
        this.isLoading = false
        this.close()
      }
    },
    async editUserRole() {
      try {
        this.isLoading = true
        const payload = {
          role_slug: this.editUserForm.role,
        }
        await this.$http.patch(`/common/user/${this.selectedUser.id}/role`, payload)
        this.showEditRoleModal = false
        this.$toast.success({
          component: Toast,
          props: {
            title: 'Success',
            message: 'User role updated.',
            type: 'success',
          },
        })
        this.$emit('refresh-users')
      } catch (e) {
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Something went wrong',
            message: 'Could not update user role. Please try again.',
            type: 'error',
          },
        })
      } finally {
        this.isLoading = false
        this.close()
      }
    },
    dateToString,
  },
}
</script>
<style lang="scss" scoped>
.organizations-container {
  overflow-y: auto;
  height: calc(100vh - 444px);
}
</style>
