<template>
  <input
    type="radio"
    class="cursor-pointer"
    :checked="checked"
    @click="$emit('toggle')"
  />
</template>

<script>
export default {
  name: 'RadioButton',
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped lang="scss">
input[type='radio'] {
  accent-color: $bb-brand-purple;
  width: 25px;
  height: 25px;
}
</style>
