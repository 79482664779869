<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="15"
      cy="15"
      r="15"
      fill="#6366FA"
    />
    <mask
      id="mask0_3440_793"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="30"
      height="30"
    >
      <circle
        cx="15"
        cy="15"
        r="15"
        fill="#6366FA"
      />
    </mask>
    <g mask="url(#mask0_3440_793)">
      <circle
        cx="23.1895"
        cy="2.34375"
        r="17.3438"
        transform="rotate(-90 23.1895 2.34375)"
        fill="url(#paint0_linear_3440_793)"
      />
      <circle
        cx="3.18945"
        cy="6.09375"
        r="17.0938"
        stroke="white"
        stroke-width="0.5"
      />
    </g>
    <circle
      cx="1.875"
      cy="1.875"
      r="1.875"
      transform="matrix(-1 0 0 1 13.3457 18.75)"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_3440_793"
        x1="8.3749"
        y1="-23.9063"
        x2="35.9706"
        y2="15.994"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#84FAE4" />
        <stop
          offset="1"
          stop-color="#6366FA"
        />
      </linearGradient>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'IcAvatar',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
}
</script>
