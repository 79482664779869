<template>
  <div class="flex">
    <div class="flex-auto">
      <div class="h-35 bg-white space-y-2 px-8 py-6 flex justify-between content-center">
        <div class="space-y-2">
          <p class="text-sm">Users</p>
          <h2 class="font-medium">User Management</h2>
          <p class="text-sm text-bb-mid-grey">Manage and add new users to your organizations</p>
        </div>
        <div class="flex items-end">
          <MergeButtonRound
            :disabled="isLoading"
            button-type="primary"
            class="w-40"
            @click="showModal"
          >
            <p class="flex items-center">
              <ic-plus
                :size="16"
                class="pr-1"
              />
              <span>Invite User</span>
            </p>
          </MergeButtonRound>
        </div>
      </div>

      <div class="table-container base-scrollbar">
        <bb-table
          class="mx-8 my-6 border rounded-md"
          :headers="headers"
          :data="usersClone"
          :is-loading="isLoading"
          :scroll-y="true"
          :sticky-header="true"
          table-height="calc(100vh - 400px)"
          @select="selectUser"
        >
          <template #rows="{ tableData }">
            <user-management-rows
              :data="tableData"
              @select="selectUser"
            />
          </template>
        </bb-table>
      </div>
    </div>
    <UserDetail
      v-if="selectedUser"
      class="flex-none user-detail bg-white"
      :selected-user="selectedUser"
      @close="closeUserDetail()"
      @refresh-users="getAllUsers"
      @toggle-assign-user-org-modal="toggleAssignUserOrgModal"
    />
    <!--MODAL COMPONENTS-->
    <bb-base-modal
      v-if="showInviteUserModal"
      :width="size.width"
      :height="size.height"
      :form-values="formValues"
      :is-modal-loading="isModalLoading"
      @close="closeModal"
    >
      <template #container="{ loadingStatus }">
        <invite-user-modal-form
          :is-loading="loadingStatus"
          :options="organizationOptions"
          :existing-user="existingUser"
          @close="closeModal"
          @save="sendInvitation"
        />
      </template>
    </bb-base-modal>

    <!--MODAL COMPONENTS-->
    <bb-base-modal
      v-if="showAssignUserOrgModal"
      :width="size.width"
      :height="size.height"
      :is-modal-loading="isModalLoading"
      @close="showAssignUserOrgModal = false"
    >
      <template #container="{ loadingStatus }">
        <assign-user-org-form
          :is-loading="loadingStatus"
          :options="organizationOptions"
          :selected-user="selectedUser"
          @close="showAssignUserOrgModal = false"
          @save="assignUserOrg"
        />
      </template>
    </bb-base-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'

import { MODULE_NAME as USER_MODULE, ACTIONS } from '@/store/modules/user/user'
import BbTable from '@/components/table/BbTable'
import UserManagementRows from '@/components/table/table_rows/UserManagementRows'
import MergeButtonRound from '@/components/btn/MergeButtonRound'
import UserDetail from '@/components/user-management/UserDetail'
import InviteUserModalForm from '@/components/modals/forms/InviteUserModalForm'
import BbBaseModal from '@/components/modals/brightbid/BbBaseModal'
import AssignUserOrgForm from '@/components/modals/forms/AssignUserOrgForm'
import Toast from '@/components/shared/Toast'

export default {
  name: 'UserManagementView',
  components: {
    BbTable,
    MergeButtonRound,
    UserManagementRows,
    UserDetail,
    InviteUserModalForm,
    BbBaseModal,
    AssignUserOrgForm,
  },
  data() {
    return {
      headers: [
        {
          value: 'name',
          label: 'Name',
          sortable: true,
          sorting: null,
          position: 'left',
        },
        {
          value: 'email',
          label: 'Email',
          sortable: false,
          sorting: null,
          position: 'left',
        },
        {
          value: 'role',
          label: 'Role',
          sortable: false,
          sorting: null,
          position: 'left',
        },
        {
          value: 'organizations',
          label: 'Organizations',
          sortable: false,
          sorting: null,
          position: 'left',
        },
        {
          value: 'last login at',
          label: 'Last Login',
          sortable: false,
          sorting: null,
          position: 'left',
        },
      ],
      isLoading: false,
      selectedUser: null,
      isModalLoading: false,
      formValues: null,
      size: null,
      existingUser: null,
      showAssignUserOrgModal: false,
    }
  },
  computed: {
    ...mapState('organization', ['userOrganization']),
    ...mapState('auth', ['user']),
    ...mapGetters({
      users: `${USER_MODULE}/users`,
      loadingUsers: `${USER_MODULE}/isUsersLoading`,
      usersError: `${USER_MODULE}/usersLoadingError`,
    }),
    usersClone() {
      return structuredClone(this.users)
    },
    organizationOptions() {
      return this.userOrganization.map(item => {
        return {
          label: item.name,
          value: item.id,
        }
      })
    },
    showInviteUserModal() {
      return this.formValues !== null
    },
  },
  async mounted() {
    this.isLoading = true
    await this.getAllUsers()
    this.isLoading = false
  },
  methods: {
    ...mapActions({
      loadUsers: `${USER_MODULE}/${ACTIONS.LOAD_USERS}`,
    }),
    closeUserDetail() {
      this.selectedUser = null
    },
    selectUser(user) {
      this.selectedUser = user
    },
    showModal() {
      this.formValues = { modal: 'assign-to-user' }
      this.size = { width: '800px', height: '662px' }
    },
    closeModal() {
      this.formValues = null
    },
    async sendInvitation(invitationInfo) {
      if (!invitationInfo) return
      try {
        this.isModalLoading = true
        const payload = {
          email: invitationInfo.email,
          role_slug: invitationInfo.role,
          organization_ids: invitationInfo.organizations.map(item => item.value),
        }

        await this.$http.post('common/user/invite', payload)

        // close when done
        this.closeModal()
        this.$toast.success({
          component: Toast,
          props: {
            title: 'Success',
            message: 'Successfully sent an invitation.',
            type: 'success',
          },
        })
      } catch (error) {
        if (error.response.data === 'User with this email already exists' && error.response.status === 409)
          this.existingUser = invitationInfo.email
      } finally {
        this.isModalLoading = false
      }
    },
    async getAllUsers() {
      try {
        await this.loadUsers()
      } catch (error) {
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: 'Failed to fetch users',
            type: 'error',
          },
        })
      }
    },
    toggleAssignUserOrgModal(showModal = true) {
      this.showAssignUserOrgModal = showModal
      this.size = { width: '570px', height: '662px' }
    },
    async assignUserOrg(formData) {
      try {
        this.isModalLoading = true
        const payload = {
          user_ids: [this.selectedUser.id],
          organization_ids: formData.organizations.map(item => item.value),
        }
        const resp = await this.$http.post('common/user/assign-users-to-organizations', payload)

        if (resp && resp.status >= 400) throw resp.data?.message
        if (resp && resp.status === 200) {
          await this.getAllUsers()
          this.updateSelectedUser()
          this.$toast.success({
            component: Toast,
            props: {
              title: 'Success',
              message: resp.data?.message ? resp.data.message : 'User assigned to organizations successfully.',
              type: 'success',
            },
          })
        }

        this.showAssignUserOrgModal = false
      } catch (error) {
        this.$toast.warning({
          component: Toast,
          props: {
            title: 'Failed',
            message: error ? error : 'Failed to assign user to an organization.',
            type: 'warning',
          },
        })
      } finally {
        this.isModalLoading = false
      }
    },
    updateSelectedUser() {
      const newSelectedUser = this.users.filter(item => item.id == this.selectedUser.id)
      this.selectedUser = newSelectedUser.length > 0 ? newSelectedUser[0] : this.selectedUser
    },
  },
}
</script>

<style scoped lang="scss">
.table-container {
  overflow-y: auto;
  height: calc(100vh - 243px);
}

.user-detail {
  width: 320px;
}
</style>
