<template>
  <div class="bg-white">
    <div
      v-if="isLoading"
      class="fixed inset-0 bg-white z-50 bg-opacity-75 transition-opacity rounded-lg"
    >
      <div class="h-full flex items-center justify-center">
        <brightbid-loader size="120">
          <p>Assigning organization...</p>
        </brightbid-loader>
      </div>
    </div>

    <div class="text-bb-text-default mx-10 mt-10 mb-6 space-y-8">
      <h3 class="h3 text-center">Assign Organization</h3>
      <p>Choose one or more organizations to be managed by this user.</p>
    </div>

    <div class="my-6 overflow-y-auto max-h-100 base-scrollbar pb-40">
      <form class="mx-10 mb-6">
        <div class="space-y-6">
          <div class="space-y-6">
            <div>
              <h4 class="h4">Organization</h4>
            </div>
            <div class="space-y-4">
              <div
                v-for="(organization, index) in $v.assignUserOrgForm.organizations.$each.$iter"
                :key="`${organization.$model.value}-${index}`"
              >
                <div class="w-full flex items-center gap-1">
                  <search-input
                    v-scroll-to-me
                    v-model="organization.$model"
                    :input-name="organization.$model.value"
                    :input-id="index"
                    :options="filteredOptions"
                    :placeholder="'Select organization...'"
                    class="w-full"
                    :error-message="organization.$error ? 'Please select an organization' : null"
                    @blur="organization.$touch"
                    @select-item="updateOrganizationList"
                  />
                  <ic-cross
                    v-if="assignUserOrgForm.organizations.length > 1"
                    @close="removeOrganizationInput(index)"
                  />
                </div>
              </div>
              <div
                class="cursor-pointer"
                @click="addOrganizationInput"
              >
                <p
                  class="font-medium"
                  :class="hasAtLeastOneOrganization ? 'text-bb-brand-purple' : 'text-bb-disabled-gray'"
                >
                  + Add Organization
                </p>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <!--SAVE AND CANCEL BUTTONS-->
    <div class="absolute inset-x-0 bottom-0 flex justify-between items-center m-10">
      <merge-button-round
        :disabled="isLoading"
        button-type="secondary"
        class="w-40"
        @click="$emit('close')"
      >
        <p>Cancel</p>
      </merge-button-round>
      <merge-button-round
        :disabled="isLoading"
        button-type="primary"
        class="w-40"
        @click="save"
      >
        <p>Save</p>
      </merge-button-round>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import IcCross from '@/components/icon/ic-cross'
import SearchInput from '@/components/input/brightbid/SearchInput'
import BrightbidLoader from '@/components/loader/BrightbidLoader'
import MergeButtonRound from '@/components/btn/MergeButtonRound'

export default {
  name: 'AssignUserOrgForm',
  components: {
    SearchInput,
    IcCross,
    BrightbidLoader,
    MergeButtonRound,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    selectedUser: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      assignUserOrgForm: {
        organizations: [{ value: null, label: null }],
      },
      userOrganizationIDs: [],
    }
  },
  computed: {
    filteredOptions() {
      return this.userOrganizationOptions.filter(item => !this.assignUserOrgForm.organizations.some(org => org.value === item.value))
    },
    hasAtLeastOneOrganization() {
      const organizations = this.assignUserOrgForm.organizations
      // Check if organizations array exists, has at least one item, and the first organization has a defined value
      return (
        organizations &&
        organizations.length > 0 &&
        organizations[0].value !== undefined &&
        organizations[0].value !== null
      )
    },
  },
  created(){
    const userOrganizationIDs = this.selectedUser.organizations.map(item => item.id)
    this.userOrganizationOptions = this.options.filter(item => !userOrganizationIDs.includes(item.value))
  },
  validations() {
    return {
      assignUserOrgForm: {
        organizations: {
          required,
          $each: { label: { required }, value: { required } },
        },
      },
    }
  },
  methods: {
    addOrganizationInput() {
      if (!this.hasAtLeastOneOrganization) return
      const emptySearchInput = { value: '', label: '' }
      this.assignUserOrgForm.organizations.push(emptySearchInput)
    },
    removeOrganizationInput(index) {
      if (this.assignUserOrgForm.organizations.length === 1) return
      this.assignUserOrgForm.organizations.splice(index, 1)
    },
    save() {
      this.$v.assignUserOrgForm.$touch()
      if (this.$v.assignUserOrgForm.$invalid) return
      this.$emit('save', this.assignUserOrgForm)
    },
    updateOrganizationList(data, index) {
      this.assignUserOrgForm.organizations.splice(index, 1, data)
    }
  }
}
</script>

<style scoped></style>
